// eslint-disable-next-line import/prefer-default-export
export const bicUtils = {
  getBic: (code) => {
    const bicCode = {};
    bicCode['0001'] = 'BSABESBBXXX';
    bicCode['0003'] = 'BDEPESM1XXX';
    bicCode['0004'] = 'POPUESMMXXX';
    bicCode['0008'] = 'BSABESBBXXX';
    bicCode['0009'] = 'BBVAESMMXXX';
    bicCode['0010'] = 'BBVAESMMXXX';
    bicCode['0013'] = 'BSABESBBXXX';
    bicCode['0015'] = 'BBVAESMMXXX';
    bicCode['0016'] = 'BSCHESMMXXX';
    bicCode['0019'] = 'DEUTESBBXXX';
    bicCode['0020'] = 'BBVAESMMXXX';
    bicCode['0021'] = 'BDEPESM1XXX';
    bicCode['0024'] = 'POPUESMMXXX';
    bicCode['0029'] = 'CSSOES2SXXX';
    bicCode['0030'] = 'ESPCESMMXXX';
    bicCode['0031'] = 'ETCHES2GXXX';
    bicCode['0035'] = 'BBVAESMMXXX';
    bicCode['0036'] = 'SABNESMMXXX';
    bicCode['0041'] = 'CAIXESBBXXX';
    bicCode['0042'] = 'BSABESBBXXX';
    bicCode['0043'] = 'BSABESBBXXX';
    bicCode['0044'] = 'BBVAESMMXXX';
    bicCode['0045'] = 'BBVAESMMXXX';
    bicCode['0046'] = 'GALEES2GXXX';
    bicCode['0049'] = 'BSCHESMMXXX';
    bicCode['0050'] = 'BBVAESMMXXX';
    bicCode['0053'] = 'BSCHESMMXXX';
    bicCode['0056'] = 'CAHMESMMXXX';
    bicCode['0057'] = 'BVADESMMXXX';
    bicCode['0058'] = 'BNPAESMMXXX';
    bicCode['0059'] = 'MADRESMMXXX';
    bicCode['0061'] = 'BMARES2MXXX';
    bicCode['0062'] = 'BBVAESMMXXX';
    bicCode['0063'] = 'CAHMESMMXXX';
    bicCode['0065'] = 'BARCESMMXXX';
    bicCode['0067'] = 'BSCHESMMXXX';
    bicCode['0068'] = 'BBVAESMMXXX';
    bicCode['0069'] = 'CAIXESBBXXX';
    bicCode['0072'] = 'POPUESMMXXX';
    bicCode['0073'] = 'OPENESMMXXX';
    bicCode['0075'] = 'POPUESMMXXX';
    bicCode['0076'] = 'BSABESBBXXX';
    bicCode['0077'] = 'UNOEESM1XXX';
    bicCode['0078'] = 'BAPUES22XXX';
    bicCode['0081'] = 'BSABESBBXXX';
    bicCode['0082'] = 'POPUESMMXXX';
    bicCode['0085'] = 'BSCHESMMXXX';
    bicCode['0086'] = 'NORTESMMXXX';
    bicCode['0087'] = 'CAHMESMMXXX';
    bicCode['0093'] = 'CAIXESBBXXX';
    bicCode['0094'] = 'BVALESMMXXX';
    bicCode['0095'] = 'POPUESMMXXX';
    bicCode['0097'] = 'POPUESMMXXX';
    bicCode['0099'] = 'CAHMESMMXXX';
    bicCode['0100'] = 'ESPCESMMXXX';
    bicCode['0101'] = 'CAIXESBBXXX';
    bicCode['0102'] = 'BBVAESMMXXX';
    bicCode['0103'] = 'BARCESMMXXX';
    bicCode['0104'] = 'BBVAESMMXXX';
    bicCode['0107'] = 'BNPAESMSXXX';
    bicCode['0108'] = 'SOGEESMMXXX';
    bicCode['0109'] = 'ESPCESMMXXX';
    bicCode['0112'] = 'BSABESBBXXX';
    bicCode['0114'] = 'CAIXESBBXXX';
    bicCode['0118'] = 'BSABESBBXXX';
    bicCode['0119'] = 'BSABESBBXXX';
    bicCode['0122'] = 'CITIES2XXXX';
    bicCode['0124'] = 'CESCESBBXXX';
    bicCode['0125'] = 'BAOFESM1XXX';
    bicCode['0127'] = 'BBVAESMMXXX';
    bicCode['0128'] = 'BKBKESMMXXX';
    bicCode['0130'] = 'CGDIESMMXXX';
    bicCode['0131'] = 'BESMESMMXXX';
    bicCode['0133'] = 'MIKBESB1XXX';
    bicCode['0136'] = 'AREBESMMXXX';
    bicCode['0137'] = 'BBVAESMMXXX';
    bicCode['0138'] = 'BKOAES22XXX';
    bicCode['0142'] = 'CAIXESBBXXX';
    bicCode['0149'] = 'BNPAESMSXXX';
    bicCode['0151'] = 'CHASESM3XXX';
    bicCode['0154'] = 'BSUIESMMXXX';
    bicCode['0155'] = 'BRASESMMXXX';
    bicCode['0156'] = 'ABNAESMMXXX';
    bicCode['0159'] = 'COBAESMXXXX';
    bicCode['0160'] = 'BOTKESMXXXX';
    bicCode['0162'] = 'MIDLESMMXXX';
    bicCode['0167'] = 'GEBAESMMXXX';
    bicCode['0168'] = 'BBRUESMXXXX';
    bicCode['0169'] = 'NACNESMMXXX';
    bicCode['0182'] = 'BBVAESMMXXX';
    bicCode['0184'] = 'BEDFESM1XXX';
    bicCode['0185'] = 'BSABESBBXXX';
    bicCode['0186'] = 'BFIVESBBXXX';
    bicCode['0188'] = 'ALCLESMMXXX';
    bicCode['0190'] = 'BBPIESMMXXX';
    bicCode['0196'] = 'WELAESMMXXX';
    bicCode['0198'] = 'BCOEESMMXXX';
    bicCode['0200'] = 'PRVBESB1XXX';
    bicCode['0202'] = 'BBVAESMMXXX';
    bicCode['0205'] = 'DEUTESBBXXX';
    bicCode['0208'] = 'CSSOES2SXXX';
    bicCode['0209'] = 'BSABESBBXXX';
    bicCode['0210'] = 'GALEES2GXXX';
    bicCode['0216'] = 'POHIESMMXXX';
    bicCode['0219'] = 'BMCEESMMXXX';
    bicCode['0220'] = 'FIOFESM1XXX';
    bicCode['0224'] = 'SCFBESMMXXX';
    bicCode['0226'] = 'UBSWESMMXXX';
    bicCode['0227'] = 'UNOEESM1XXX';
    bicCode['0229'] = 'POPLESMMXXX';
    bicCode['0230'] = 'BSABESBBXXX';
    bicCode['0232'] = 'INVLESMMXXX';
    bicCode['0233'] = 'POPIESMMXXX';
    bicCode['0234'] = 'CCOCESMMXXX';
    bicCode['0235'] = 'PICHESMMXXX';
    bicCode['0237'] = 'CSURES2CXXX';
    bicCode['0238'] = 'PSTRESMMXXX';
    bicCode['0239'] = 'EVOBESMMXXX';
    bicCode['0486'] = 'CECAESMM086';
    bicCode['0487'] = 'GBMNESMMXXX';
    bicCode['0490'] = 'CAIXESBBXXX';
    bicCode['1001'] = 'BBVAESMMXXX';
    bicCode['1004'] = 'BBVAESMMXXX';
    bicCode['1005'] = 'BBVAESMMXXX';
    bicCode['1301'] = 'BBVAESMMXXX';
    bicCode['1302'] = 'BBVAESMMXXX';
    bicCode['1460'] = 'CRESESMMXXX';
    bicCode['1465'] = 'INGDESMMXXX';
    bicCode['1469'] = 'BBVAESMMXXX';
    bicCode['1474'] = 'CITIESMXXXX';
    bicCode['1480'] = 'VOWAES21XXX';
    bicCode['1484'] = 'ESPCESMMXXX';
    bicCode['1485'] = 'BOFAES2XXXX';
    bicCode['1490'] = 'SELFESMMXXX';
    bicCode['1491'] = 'TRIOESMMXXX';
    bicCode['1494'] = 'BCITESMMXXX';
    bicCode['1513'] = 'CGDIESMMXXX';
    bicCode['1524'] = 'UBIBESMMXXX';
    bicCode['1534'] = 'KBLXESMMXXX';
    bicCode['1544'] = 'BACAESMMXXX';
    bicCode['1545'] = 'AGRIESMMXXX';
    bicCode['2000'] = 'CECAESMMXXX';
    bicCode['2001'] = 'CECAESMM105';
    bicCode['2005'] = 'BSABESBBXXX';
    bicCode['2006'] = 'UCJAES2MXXX';
    bicCode['2007'] = 'UCJAES2MXXX';
    bicCode['2010'] = 'CECAESMM010';
    bicCode['2011'] = 'CAIXESBBXXX';
    bicCode['2012'] = 'CAIXESBBXXX';
    bicCode['2013'] = 'CESCESBBXXX';
    bicCode['2015'] = 'BASKES2BXXX';
    bicCode['2016'] = 'BASKES2BXXX';
    bicCode['2017'] = 'CECAESMM017';
    bicCode['2018'] = 'CAIXESBBXXX';
    bicCode['2019'] = 'CECAESMM099';
    bicCode['2020'] = 'UCJAES2MXXX';
    bicCode['2021'] = 'CAHMESMMXXX';
    bicCode['2022'] = 'CAHMESMMXXX';
    bicCode['2024'] = 'CSURES2CXXX';
    bicCode['2025'] = 'CSURES2CXXX';
    bicCode['2028'] = 'CECAESMM105';
    bicCode['2030'] = 'CAIXESBBXXX';
    bicCode['2031'] = 'CECAESMM031';
    bicCode['2032'] = 'CAIXESBBXXX';
    bicCode['2033'] = 'CAIXESBBXXX';
    bicCode['2034'] = 'CAIXESBBXXX';
    bicCode['2035'] = 'CSPAES2LXXX';
    bicCode['2037'] = 'CAHMESMMXXX';
    bicCode['2038'] = 'CAHMESMMXXX';
    bicCode['2039'] = 'UCJAES2MXXX';
    bicCode['2040'] = 'BBVAESMMXXX';
    bicCode['2041'] = 'CESCESBBXXX';
    bicCode['2042'] = 'CAHMESMMXXX';
    bicCode['2043'] = 'CECAESMM043';
    bicCode['2045'] = 'CECAESMM045';
    bicCode['2046'] = 'CAGLESMMXXX';
    bicCode['2048'] = 'CECAESMM048';
    bicCode['2049'] = 'CSPAES2LXXX';
    bicCode['2051'] = 'CECAESMM051';
    bicCode['2052'] = 'CAHMESMMXXX';
    bicCode['2053'] = 'CAIXESBBXXX';
    bicCode['2054'] = 'CAIXESBBXXX';
    bicCode['2055'] = 'CECAESMM099';
    bicCode['2056'] = 'CECAESMM056';
    bicCode['2057'] = 'CAGLESMMXXX';
    bicCode['2058'] = 'UCJAES2MXXX';
    bicCode['2059'] = 'BBVAESMMXXX';
    bicCode['2060'] = 'CAHMESMMXXX';
    bicCode['2061'] = 'CSSOES2SXXX';
    bicCode['2062'] = 'BASKES2BXXX';
    bicCode['2063'] = 'BASKES2BXXX';
    bicCode['2065'] = 'CAIXESBBXXX';
    bicCode['2066'] = 'CECAESMM066';
    bicCode['2068'] = 'CAHMESMMXXX';
    bicCode['2069'] = 'CAHMESMMXXX';
    bicCode['2070'] = 'CAIXESBBXXX';
    bicCode['2071'] = 'CAIXESBBXXX';
    bicCode['2072'] = 'CSSOES2SXXX';
    bicCode['2073'] = 'CESCESBBXXX';
    bicCode['2074'] = 'BBVAESMMXXX';
    bicCode['2075'] = 'CECAESMM105';
    bicCode['2076'] = 'BSABESBBXXX';
    bicCode['2077'] = 'CAHMESMMXXX';
    bicCode['2078'] = 'CSPAES2LXXX';
    bicCode['2079'] = 'CSPAES2LXXX';
    bicCode['2080'] = 'CAGLESMMXXX';
    bicCode['2081'] = 'BSABESBBXXX';
    bicCode['2082'] = 'BASKES2BXXX';
    bicCode['2083'] = 'BASKES2BXXX';
    bicCode['2084'] = 'CSPAES2LXXX';
    bicCode['2085'] = 'CAZRES2ZXXX';
    bicCode['2086'] = 'CECAESMM086';
    bicCode['2088'] = 'BBVAESMMXXX';
    bicCode['2089'] = 'CECAESMM031';
    bicCode['2090'] = 'BSABESBBXXX';
    bicCode['2091'] = 'CAGLESMMXXX';
    bicCode['2092'] = 'UCJAES2MXXX';
    bicCode['2093'] = 'BSABESBBXXX';
    bicCode['2094'] = 'CAHMESMMXXX';
    bicCode['2095'] = 'BASKES2BXXX';
    bicCode['2096'] = 'CSPAES2LXXX';
    bicCode['2097'] = 'BASKES2BXXX';
    bicCode['2098'] = 'CAIXESBBXXX';
    bicCode['2099'] = 'CECAESMM099';
    bicCode['2100'] = 'CAIXESBBXXX';
    bicCode['2101'] = 'BASKES2BXXX';
    bicCode['2102'] = 'BSABESBBXXX';
    bicCode['2103'] = 'UCJAES2MXXX';
    bicCode['2104'] = 'CSSOES2SXXX';
    bicCode['2105'] = 'CECAESMM105';
    bicCode['2106'] = 'CAIXESBBXXX';
    bicCode['2107'] = 'BBVAESMMXXX';
    bicCode['3001'] = 'BCOEESMM001';
    bicCode['3005'] = 'BCOEESMM005';
    bicCode['3007'] = 'BCOEESMM007';
    bicCode['3008'] = 'BCOEESMM008';
    bicCode['3009'] = 'BCOEESMM009';
    bicCode['3016'] = 'BCOEESMM016';
    bicCode['3017'] = 'BCOEESMM017';
    bicCode['3018'] = 'BCOEESMM018';
    bicCode['3020'] = 'BCOEESMM020';
    bicCode['3021'] = 'BCOEESMM191';
    bicCode['3022'] = 'BCOEESMM060';
    bicCode['3023'] = 'BCOEESMM023';
    bicCode['3024'] = 'BAOFESM1XXX';
    bicCode['3025'] = 'CDENESBBXXX';
    bicCode['3029'] = 'CCRIES2A029';
    bicCode['3035'] = 'CLPEES2MXXX';
    bicCode['3045'] = 'CCRIES2A045';
    bicCode['3054'] = 'CCRIES2AXXX';
    bicCode['3056'] = 'BCOEESMM190';
    bicCode['3057'] = 'CCRIES2AXXX';
    bicCode['3058'] = 'CCRIES2AXXX';
    bicCode['3059'] = 'BCOEESMM059';
    bicCode['3060'] = 'BCOEESMM060';
    bicCode['3061'] = 'CCRIES2AXXX';
    bicCode['3062'] = 'BCOEESMM190';
    bicCode['3063'] = 'BCOEESMM063';
    bicCode['3064'] = 'BCOEESMM190';
    bicCode['3065'] = 'BCOEESMM187';
    bicCode['3066'] = 'BCOEESMM191';
    bicCode['3067'] = 'BCOEESMM067';
    bicCode['3068'] = 'CAGLESMMXXX';
    bicCode['3069'] = 'CAZRES2ZXXX';
    bicCode['3070'] = 'BCOEESMM070';
    bicCode['3072'] = 'CCRIES2AXXX';
    bicCode['3076'] = 'BCOEESMM076';
    bicCode['3078'] = 'BCOEESMM060';
    bicCode['3079'] = 'BCOEESMM187';
    bicCode['3080'] = 'BCOEESMM080';
    bicCode['3081'] = 'BCOEESMM081';
    bicCode['3082'] = 'CCRIES2AXXX';
    bicCode['3083'] = 'CCRIES2AXXX';
    bicCode['3084'] = 'CVRVES2BXXX';
    bicCode['3085'] = 'BCOEESMM085';
    bicCode['3089'] = 'BCOEESMM089';
    bicCode['3092'] = 'BCOEESMM191';
    bicCode['3093'] = 'BCOEESMM191';
    bicCode['3094'] = 'CCRIES2AXXX';
    bicCode['3095'] = 'CCRIES2A095';
    bicCode['3096'] = 'BCOEESMM096';
    bicCode['3098'] = 'BCOEESMM098';
    bicCode['3102'] = 'BCOEESMM102';
    bicCode['3104'] = 'BCOEESMM104';
    bicCode['3105'] = 'CCRIES2A105';
    bicCode['3108'] = 'CCRIES2AXXX';
    bicCode['3110'] = 'BCOEESMM110';
    bicCode['3111'] = 'BCOEESMM111';
    bicCode['3112'] = 'CCRIES2A112';
    bicCode['3113'] = 'BCOEESMM113';
    bicCode['3114'] = 'CCRIES2AXXX';
    bicCode['3115'] = 'BCOEESMM115';
    bicCode['3116'] = 'BCOEESMM116';
    bicCode['3117'] = 'BCOEESMM117';
    bicCode['3118'] = 'CCRIES2A118';
    bicCode['3119'] = 'CCRIES2A119';
    bicCode['3121'] = 'CCRIES2A121';
    bicCode['3123'] = 'CCRIES2A123';
    bicCode['3127'] = 'BCOEESMM127';
    bicCode['3128'] = 'BCOEESMM190';
    bicCode['3129'] = 'BCOEESMM191';
    bicCode['3130'] = 'BCOEESMM130';
    bicCode['3131'] = 'CCRIES2AXXX';
    bicCode['3134'] = 'BCOEESMM134';
    bicCode['3135'] = 'CCRIES2A135';
    bicCode['3137'] = 'CCRIES2AXXX';
    bicCode['3138'] = 'BCOEESMM138';
    bicCode['3140'] = 'BCOEESMM140';
    bicCode['3141'] = 'CESCESBBXXX';
    bicCode['3144'] = 'BCOEESMM144';
    bicCode['3146'] = 'CCCVESM1XXX';
    bicCode['3147'] = 'CCRIES2AXXX';
    bicCode['3150'] = 'BCOEESMM150';
    bicCode['3152'] = 'CCRIES2A152';
    bicCode['3157'] = 'CCRIES2A157';
    bicCode['3159'] = 'BCOEESMM159';
    bicCode['3160'] = 'CCRIES2A160';
    bicCode['3161'] = 'BCOEESMM060';
    bicCode['3162'] = 'BCOEESMM162';
    bicCode['3163'] = 'CCRIES2AXXX';
    bicCode['3165'] = 'CCRIES2A165';
    bicCode['3166'] = 'BCOEESMM166';
    bicCode['3167'] = 'CCRIES2AXXX';
    bicCode['3171'] = 'BCOEESMM191';
    bicCode['3172'] = 'CCOCESMMXXX';
    bicCode['3174'] = 'BCOEESMM174';
    bicCode['3177'] = 'CCRIES2AXXX';
    bicCode['3179'] = 'CCRIES2A179';
    bicCode['3181'] = 'CCRIES2AXXX';
    bicCode['3183'] = 'CASDESBBXXX';
    bicCode['3186'] = 'CCRIES2A186';
    bicCode['3187'] = 'BCOEESMM187';
    bicCode['3188'] = 'CCRIES2AXXX';
    bicCode['3189'] = 'BCOEESMM191';
    bicCode['3190'] = 'BCOEESMM190';
    bicCode['3191'] = 'BCOEESMM191';
    bicCode['9000'] = 'ESPBESMMXXX';
    return bicCode[code];
  },
};
