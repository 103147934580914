import { render, staticRenderFns } from "./RecordType.vue?vue&type=template&id=8d199ce4"
import script from "./RecordType.vue?vue&type=script&lang=js"
export * from "./RecordType.vue?vue&type=script&lang=js"
import style0 from "./RecordType.vue?vue&type=style&index=0&id=8d199ce4&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../frontend/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports