import AffiliateService from '../services/affiliates.service';

const initialState = {
  loggedAffiliate: {},
  meetings: [],
};

// eslint-disable-next-line import/prefer-default-export
export const affiliate = {
  namespaced: true,
  state: initialState,
  actions: {
    getLoggedAffiliate({ commit }) {
      return AffiliateService.getLoggedAffiliate().then(
        (response) => {
          commit('setLoggedAffiliate', response);
        },
        (error) => Promise.reject(error),
      );
    },
    getUserMeetings({ commit }) {
      return AffiliateService.getUserMeetings().then(
        (response) => {
          commit('setUserMeetings', response);
        },
        (error) => Promise.reject(error),
      );
    },
  },
  mutations: {
    setLoggedAffiliate(state, affiliateObj) {
      state.loggedAffiliate = affiliateObj;
    },
    setUserMeetings(state, meetings) {
      state.meetings = meetings;
    },
  },
};
