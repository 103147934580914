import { render, staticRenderFns } from "./DonationCard.vue?vue&type=template&id=49589444&scoped=true"
import script from "./DonationCard.vue?vue&type=script&lang=js"
export * from "./DonationCard.vue?vue&type=script&lang=js"
import style0 from "./DonationCard.vue?vue&type=style&index=0&id=49589444&prod&scoped=true&lang=css"
import style1 from "./DonationCard.vue?vue&type=style&index=1&id=49589444&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../frontend/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49589444",
  null
  
)

export default component.exports