import { helpers } from 'vuelidate/lib/validators';

const dniValidation = (value) => {
  let dni = value;
  if (!helpers.req(value)) return true;
  let numero; let letr; let
    letra;
  const expresionRegularDni = /^[XYZ]?\d{5,8}[A-Z]$/;

  dni = dni.toUpperCase();

  if (expresionRegularDni.test(dni) === true) {
    if (dni[0] === 'X') {
      dni = dni.replace('X', 0);
    } else if (dni[0] === 'Y') {
      dni = dni.replace('Y', 1);
    } else if (dni[0] === 'Z') {
      dni = dni.replace('Z', 2);
    }
    numero = dni.substr(0, dni.length - 1);

    letr = dni.substr(dni.length - 1, 1);
    numero %= 23;
    letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
    letra = letra.substring(numero, numero + 1);

    if (letra !== letr) {
      return false;
    }
    return true;
  }
  return false;
};

export default dniValidation;
