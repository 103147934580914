import Vue from 'vue';
import Vuex from 'vuex';
import { auth } from '@/store/auth.module';
import { affiliate } from '@/store/affiliates.module';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    affiliates: affiliate,
  },
});
