import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';
import AuthService from '@/services/auth.service';
import Home from '../views/Home.vue';

import Affiliates from '../views/AdminPanel/Affiliates.vue';
import NotRegistered from '../views/AdminPanel/NotRegistered.vue';
import GlobalData from '../views/AdminPanel/GlobalData.vue';
import Records from '../views/AdminPanel/Records.vue';
import UsersManager from '../views/AdminPanel/UManag/Index.vue';
import CreateUser from '../views/AdminPanel/UManag/CreateUser.vue';

import Activity from '../views/AdminPanel/Activity/Index.vue';
import Fees from '../views/AdminPanel/Export/Fees.vue';
import Documentation from '../views/AdminPanel/Export/Documentation.vue';
import EmailEdition from '../views/AdminPanel/Export/Documents/EmailEdition.vue';
import PdfEdition from '../views/AdminPanel/Export/Documents/PdfEdition.vue';
import PdfCreation from '../views/AdminPanel/Export/Documents/PdfCreation.vue';
import Mailing from '../views/AdminPanel/Communications/Mailing.vue';
import MailingView from '../views/AdminPanel/Communications/MailingView.vue';
import CommunicationsList from '../views/AdminPanel/Communications/CommunicationsList.vue';
import CommunicationsPending from '../views/AdminPanel/Communications/CommunicationsPending.vue';
import CommunicationsSended from '../views/AdminPanel/Communications/CommunicationsSent.vue';
import Meeting from '../views/AdminPanel/Communications/Meeting.vue';
import MeetingList from '../views/AdminPanel/Communications/MeetingList.vue';
import MeetingNext from '../views/AdminPanel/Communications/MeetingNext.vue';
import MeetingPending from '../views/AdminPanel/Communications/MeetingPending.vue';

import VotesPending from '../views/AdminPanel/Voting/VotesPending.vue';
import VotesNext from '../views/AdminPanel/Voting/VotesNext.vue';
import Voting from '../views/AdminPanel/Voting/Voting.vue';
import VotingClosed from '../views/AdminPanel/Voting/VotingClosed.vue';
import VotingList from '../views/AdminPanel/Voting/VotingList.vue';
import VotingResultList from '../views/AdminPanel/Voting/VotingResultList.vue';
import VotingPanel from '../views/Panels/VotingPanel.vue';
import VotingResult from '../views/Panels/VotingResult.vue';

import AssemblyInfo from '../views/AdminPanel/MyAssembly/Information.vue';
import AssemblyStats from '../views/AdminPanel/MyAssembly/Stats.vue';
import AssemblyAffiliates from '../views/AdminPanel/MyAssembly/Table.vue';
import Asambleas from '../views/AdminPanel/AssemblyView.vue';
import AdminStats from '../views/AdminPanel/Stats.vue';
import StatsRecords from '../views/AdminPanel/StatsRecords.vue';
import FeesStats from '../views/AdminPanel/FeesStats.vue';
import Calendar from '../views/Calendar.vue';

import UserPanel from '../views/UserPanel/UserPanel.vue';
import UserNotRegistered from '../views/UserPanel/UserNotRegistered.vue';

import RecordPanel from '../views/Panels/RecordPanel.vue';
import NotRegisteredPanel from '../views/Panels/UnRegisteredPanel.vue';
import RegisteredPanel from '../views/Panels/RegisteredPanel.vue';
import ActivityPanel from '../views/Panels/ActivityPanel.vue';
import MeetingPanel from '../views/Panels/MeetingPanel.vue';

import Inscription from '../views/InscriptionView.vue';
import Validation from '../views/Validation.vue';
import PasswordRecovery from '../views/PasswordRecovery.vue';
import InscriptionResult from '../views/InscriptionResult.vue';
import LoginView from '../views/LoginView.vue';
import NotFoundView from '../views/NotFoundView.vue';
import ErrorGeneric from '../views/ErrorGeneric.vue';
import AccessDenied from '../views/AccessDenied.vue';

import AffiliatesRegister from '../views/AdminPanel/ManualRegister/AffiliatesRegister.vue';
import RecordsRegister from '../views/AdminPanel/ManualRegister/RecordsRegister.vue';
import AssemblyRegister from '../components/Assembly/AssemblyRegister.vue';
import AssemblyView from '../components/Assembly/AssemblyView.vue';

import MainMenu from '../views/Selectors/MainMenu.vue';
import CensoBase from '../views/Selectors/CensoBase.vue';
import PanelAdministracion from '../views/Selectors/PanelAdministracion.vue';
import GestionUsuarios from '../views/Selectors/GestionUsuarios.vue';
import RegistroManual from '../views/Selectors/RegistroManual.vue';
import Comunicaciones from '../views/Selectors/Comunicaciones.vue';
import Estadisticas from '../views/Selectors/Estadisticas.vue';
import MiAsamblea from '../views/Selectors/MiAsamblea.vue';
import Eventos from '../views/Selectors/Eventos.vue';
import Votos from '../views/Selectors/Votos.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Inicio',
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
  },
  {
    path: '/usuario-no-inscrito',
    name: 'Usuario no inscrito',
    component: UserNotRegistered,
  },
  {
    path: '/menu-principal',
    name: 'Menu principal',
    component: MainMenu,
  },
  {
    path: '/inscribete',
    name: 'Inscríbete',
    component: Inscription,
  },
  {
    path: '/censo-base',
    name: 'Censo Base',
    component: CensoBase,
  },
  {
    path: '/censo-base/inscritos',
    name: 'Censo de Inscritos',
    component: Affiliates,
    meta: {
      auth: true,
    },
  },
  {
    path: '/censo-base/registros',
    name: 'Registros',
    component: Records,
    meta: {
      auth: true,
    },
  },
  {
    path: '/censo-base/no-inscritos',
    name: 'Censo de No Inscritos',
    component: NotRegistered,
    meta: {
      auth: true,
    },
  },
  {
    path: '/censo-base/inscritos/:id',
    name: 'Panel de Inscrito',
    component: RegisteredPanel,
  },
  {
    path: '/censo-base/no-inscritos/:id',
    name: 'Panel de No Inscrito',
    component: NotRegisteredPanel,
  },
  {
    path: '/censo-base/registros/:id',
    name: 'Panel de Registro',
    component: RecordPanel,
  },
  {
    path: '/panel-de-usuario',
    name: 'Panel de Usuario',
    component: UserPanel,
  },
  {
    path: '/votaciones',
    name: 'Plataforma de votos',
    component: Votos,
  },
  {
    path: '/votaciones/pendientes-de-aprobacion',
    name: 'Pendientes de aprobación',
    component: VotesPending,
  },
  {
    path: '/votaciones/en-curso',
    name: 'Votaciones en curso',
    component: VotesNext,
  },
  {
    path: '/votaciones/panel-de-votaciones',
    name: 'Panel de votaciones',
    component: VotingList,
  },
  {
    path: '/votaciones/resultados',
    name: 'Resultados de votaciones',
    component: VotingResultList,
  },
  {
    path: '/votaciones/en-curso/:id',
    name: 'Panel de votación',
    component: VotingPanel,
  },
  {
    path: '/votaciones/resultados/:id',
    name: 'Panel de votación',
    component: VotingResult,
  },
  {
    path: '/votaciones/nueva-votacion',
    name: 'Nueva votación',
    component: Voting,
  },
  {
    path: '/votaciones/edicion-de-votacion/:id',
    name: 'Edición de votación',
    component: Voting,
  },
  {
    path: '/votaciones/aprobadas/:id',
    name: 'Votación aprobada',
    component: VotingClosed,
  },
  {
    path: '/eventos',
    name: 'Eventos',
    component: Eventos,
  },
  {
    path: '/eventos/calendario',
    name: 'Calendario',
    component: Calendar,
  },
  {
    path: '/eventos/nuevo-evento',
    name: 'Nuevo evento',
    component: Meeting,
  },
  {
    path: '/eventos/reuniones-y-asambleas',
    name: 'Reuniones y Asambleas',
    component: MeetingList,
  },
  {
    path: '/eventos/proximos-eventos',
    name: 'Próximos eventos',
    component: MeetingNext,
  },
  {
    path: '/eventos/sin-aprobacion',
    name: 'Eventos sin aprobación',
    component: MeetingPending,
  },
  {
    path: '/eventos/reuniones-y-asambleas/:id',
    name: 'Panel de evento',
    component: MeetingPanel,
  },
  {
    path: '/comunicaciones',
    name: 'Comunicaciones',
    component: Comunicaciones,
  },
  {
    path: '/comunicaciones/nueva-comunicacion',
    name: 'Nueva comunicación',
    component: Mailing,
  },
  {
    path: '/comunicaciones/panel-comunicacion/:id',
    name: 'Panel de comunicación',
    component: MailingView,
  },
  {
    path: '/comunicaciones/edicion-comunicacion/:id',
    name: 'Edición de comunicación',
    component: Mailing,
  },
  {
    path: '/comunicaciones/lista-comunicaciones',
    name: 'Lista de Comunicaciones',
    component: CommunicationsList,
  },
  {
    path: '/comunicaciones/comunicaciones-pendientes',
    name: 'Comunicaciones pendientes',
    component: CommunicationsPending,
  },
  {
    path: '/comunicaciones/comunicaciones-finalizadas',
    name: 'Comunicaciones finalizadas',
    component: CommunicationsSended,
  },
  {
    path: '/panel-administracion',
    name: 'Panel de Administración',
    component: PanelAdministracion,
  },
  {
    path: '/panel-administracion/registro-manual',
    name: 'Registro Manual',
    component: RegistroManual,
  },
  {
    path: '/panel-administracion/registro-manual/inscritos',
    name: 'Nuevo Inscrito',
    component: AffiliatesRegister,
  },
  {
    path: '/panel-administracion/registro-manual/registros',
    name: 'Nuevo Registro',
    component: RecordsRegister,
  },
  {
    path: '/panel-administracion/datos-generales',
    name: 'Datos Generales',
    component: GlobalData,
  },
  {
    path: '/panel-administracion/asambleas',
    name: 'Asambleas',
    component: Asambleas,
  },
  {
    path: '/panel-administracion/asambleas/asamblea/:id',
    name: 'Asamblea',
    component: AssemblyView,
  },
  {
    path: '/panel-administracion/asambleas/nueva-asamblea',
    name: 'Nueva asamblea',
    component: AssemblyRegister,
  },
  {
    path: '/panel-administracion/exportacion-cuotas',
    name: 'Exportación de Cuotas',
    component: Fees,
  },
  {
    path: '/panel-administracion/documentos-legales',
    name: 'Documentos legales',
    component: Documentation,
  },
  {
    path: '/panel-administracion/documentos-legales/pdf/:id',
    name: 'Edición de PDF',
    component: PdfEdition,
  },
  {
    path: '/panel-administracion/documentos-legales/nuevo-pdf',
    name: 'Nuevo PDF',
    component: PdfCreation,
  },
  {
    path: '/panel-administracion/documentos/email/:id',
    name: 'Email',
    component: EmailEdition,
  },
  {
    path: '/gestion-usuarios',
    name: 'Gestión de usuarios',
    component: GestionUsuarios,
  },
  {
    path: '/gestion-usuarios/panel-usuarios',
    name: 'Panel de Usuarios',
    component: UsersManager,
  },
  {
    path: '/gestion-usuarios/panel-usuarios/:id',
    name: 'Usuario',
    component: ActivityPanel,
  },
  {
    path: '/gestion-usuarios/nuevo-usuario',
    name: 'Nuevo Usuario',
    component: CreateUser,
  },
  {
    path: '/gestion-usuarios/actividad',
    name: 'Actividad',
    component: Activity,
  },

  {
    path: '/estadisticas',
    name: 'Estadísticas',
    component: Estadisticas,
  },
  {
    path: '/estadisticas/inscritos',
    name: 'Estadísticas de inscritos',
    component: AdminStats,
  },
  {
    path: '/estadisticas/registros',
    name: 'Estadísticas de registros',
    component: StatsRecords,
  },
  {
    path: '/estadisticas/cuotas',
    name: 'Estadísticas de cuotas',
    component: FeesStats,
  },
  {
    path: '/mi-asamblea',
    name: 'Mi Asamblea',
    component: MiAsamblea,
  },
  {
    path: '/mi-asamblea/informacion',
    name: 'Información de Asamblea',
    component: AssemblyInfo,
  },
  {
    path: '/mi-asamblea/estadisticas',
    name: 'Estadísticas de Asamblea',
    component: AssemblyStats,
  },
  {
    path: '/mi-asamblea/inscritos',
    name: 'Tablón de inscritos',
    component: AssemblyAffiliates,
  },
  {
    path: '/validacion',
    name: 'Validación',
    component: Validation,
  },
  {
    path: '/recuperacion-contrasena',
    name: 'Recuperación de contraseña',
    component: PasswordRecovery,
  },
  {
    path: '/inscripcion/:result',
    name: 'Resultado Inscripción',
    component: InscriptionResult,
  },
  {
    path: '/error-no-encontrado',
    name: 'No encontrado',
    component: NotFoundView,
  },
  {
    path: '/acceso-denegado',
    name: 'Acceso denegado',
    component: AccessDenied,
  },
  {
    path: '/error',
    name: 'Error',
    component: ErrorGeneric,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, _from, next) => {
  if (to.name === 'Login' || to.name === 'Validación' || to.name === 'Recuperación de contraseña' || to.name === 'Inscríbete' || to.name === 'Resultado Inscripción') {
    next();
  } else if (!store.state.auth.status.loggedIn
      || AuthService.getDecodedJwtToken(store.state.auth.user).exp * 1000 < new Date().getTime()) {
    store.dispatch('auth/logout');
    next({ name: 'Login' });
  } else {
    next();
  }
});

export default router;
