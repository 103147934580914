import { render, staticRenderFns } from "./SimpleCard.vue?vue&type=template&id=f921fc08"
import script from "./SimpleCard.vue?vue&type=script&lang=js"
export * from "./SimpleCard.vue?vue&type=script&lang=js"
import style0 from "./SimpleCard.vue?vue&type=style&index=0&id=f921fc08&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../frontend/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports