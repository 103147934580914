import { helpers } from 'vuelidate/lib/validators';

// Validar IBAN español
const esBankValidation = (value) => {
  if (!helpers.req(value)) return true;
  const iban = value.replace(/ /g, '');
  if (iban.length < 24 || iban.length > 24) return false;
  if (iban.length === 24) {
    const countryCode = iban.substring(0, 2);
    if (countryCode !== 'ES') return false;
  }
  return true;
};
export default esBankValidation;
