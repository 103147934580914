import { render, staticRenderFns } from "./RecordRegister.vue?vue&type=template&id=2d978aaa"
import script from "./RecordRegister.vue?vue&type=script&lang=js"
export * from "./RecordRegister.vue?vue&type=script&lang=js"
import style0 from "./RecordRegister.vue?vue&type=style&index=0&id=2d978aaa&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../frontend/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports