import { render, staticRenderFns } from "./InscriptionResult.vue?vue&type=template&id=615fe4f8&scoped=true"
import script from "./InscriptionResult.vue?vue&type=script&lang=js"
export * from "./InscriptionResult.vue?vue&type=script&lang=js"
import style0 from "./InscriptionResult.vue?vue&type=style&index=0&id=615fe4f8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../frontend/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "615fe4f8",
  null
  
)

export default component.exports