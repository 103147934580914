import { render, staticRenderFns } from "./Estadisticas.vue?vue&type=template&id=518975b5&scoped=true"
import script from "./Estadisticas.vue?vue&type=script&lang=js"
export * from "./Estadisticas.vue?vue&type=script&lang=js"
import style0 from "./Estadisticas.vue?vue&type=style&index=0&id=518975b5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../frontend/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "518975b5",
  null
  
)

export default component.exports