import { render, staticRenderFns } from "./AssemblyRegister.vue?vue&type=template&id=0af34737"
import script from "./AssemblyRegister.vue?vue&type=script&lang=js"
export * from "./AssemblyRegister.vue?vue&type=script&lang=js"
import style0 from "./AssemblyRegister.vue?vue&type=style&index=0&id=0af34737&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../frontend/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports